import React, { useState, useCallback } from "react";
import { useLoadStakeholdersRegistrationDataMutation } from "../../api/facilityManagementApi";
import useExportToCSV from "../useExportToCSV";
import { StakeholdersInfo, StakeholdersRegistrationsData } from "../../types";
import { useDebouncedCallback } from "use-debounce";
import { useParams } from "react-router-dom";
export default function useStakeholdersRegistration(
  sortedFacilityObjectsStakeholders?: StakeholdersInfo[],
): Props {
  const { customerToken = "" } = useParams();
  const [selectedTenants, setSelectedTenants] = useState<StakeholdersInfo[]>(
    [],
  );

  const [
    sendStakeholdersRegistrationData,
    { data: registrationData, isLoading, isError },
  ] = useLoadStakeholdersRegistrationDataMutation();

  const { exportToCSV } = useExportToCSV(registrationData);

  const handleGetInfoUsersAndCode = useDebouncedCallback(
    async (stakeholder?: StakeholdersInfo) => {
      await sendStakeholdersRegistrationData({
        customerToken: customerToken ? customerToken : "",
        ids: stakeholder
          ? [stakeholder.addressId]
          : selectedTenants.map((tenant) => tenant.addressId),
      });
    },
    300,
  );

  const handleOnChange = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      if (checked && sortedFacilityObjectsStakeholders) {
        setSelectedTenants(sortedFacilityObjectsStakeholders);
      } else {
        setSelectedTenants([]);
      }
    },
    [sortedFacilityObjectsStakeholders],
  );

  return {
    selectedStakeholders: selectedTenants,
    setSelectedStakeholders: setSelectedTenants,
    handleGetInfoUsersAndCode,
    handleOnChange,
    exportToCSV,
    isLoading,
    isError,
    registrationData,
  };
}

type Props = {
  exportToCSV: () => void;
  handleOnChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  handleGetInfoUsersAndCode: (item?: StakeholdersInfo) => void;
  setSelectedStakeholders: React.Dispatch<
    React.SetStateAction<StakeholdersInfo[]>
  >;
  selectedStakeholders: StakeholdersInfo[];
  isLoading: boolean;
  registrationData?: StakeholdersRegistrationsData[];
  isError: boolean;
};
