import { Link, useOutletContext } from "react-router-dom";
import useFetchUserProfile from "../../../hooks/useFetchUserProfile";

import { Meeting } from "../../../types";
import moment from "moment";
import useGetMyRelatedParticipants from "../../../hooks/meetings/useGetMyRelatedParticipants";
import InvitationActions from "../../meetings/invitation/InvitationActions";

import InvitationSkeleton from "../../skeleton/InvitationSkeleton";

import "../../../styles/components/pages/meetings/InvitationPage.scss";
import InvitationLinkEditor from "../../meetings/invitation/InvitationLinkEditor";
import React, { useState } from "react";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { MeetingState, UserRoles } from "../../../enums";
import IconButton from "../../common/IconButton";
import Pencil from "../../icons/Pencil";
import LinkifyContent from "../../common/LinkifyContent";

export default function InvitationPage() {
  const [title, meeting] = useOutletContext<[string, Meeting]>();
  const [isEditorOpen, setEditorOpen] = useState(false);
  const profile = useFetchUserProfile();
  const { myRelatedParticipants, isFetching } = useGetMyRelatedParticipants();
  const isEmployee = useCustomerRoles().includes(UserRoles.Employee);

  const gender = profile?.gender || "";
  const prefix =
    gender.toLowerCase() === "weiblich"
      ? "Sehr geehrte"
      : gender.toLowerCase() === "männlich"
        ? "Sehr geehrter"
        : "Sehr geehrte/r";

  return (
    <div className="meeting-invitation">
      <h2 className="meeting-invitation-headline">Einladung zur {title}</h2>
      <p className="meeting-invitation-content">{`${prefix} ${profile.firstName} ${profile.lastName},`}</p>
      <p className="meeting-invitation-content">
        Wir laden Sie zur {title} ein.
      </p>
      {meeting && (
        <dl className="meeting-invitation-list">
          <dt className="meeting-invitation-list-title">Zeitpunkt:</dt>

          <dd className="meeting-invitation-list-content">{`${moment(
            meeting.startDate,
          ).format("LLL z")} ${
            meeting.endDate
              ? " - " + moment(meeting.endDate).format("LT z")
              : ""
          } ${
            moment(meeting.endDate).format("LT z").includes("PM") ? "" : "Uhr"
          }`}</dd>

          <dt className="meeting-invitation-list-title">Online:</dt>
          <dd className="meeting-invitation-list-content link">
            {!isEditorOpen && (
              <div className="meeting-invitation-list-content-text">
                {meeting.onlineMeetingInformation === null && (
                  <span>
                    HOMECASE und Online Meeting (Link folgt von Ihrem
                    Hausverwalter)
                  </span>
                )}

                <LinkifyContent linkify={true}>
                  <p className="link-item-text" data-testid="link-text">
                    {meeting.onlineMeetingInformation}
                  </p>
                </LinkifyContent>
                {isEmployee && (
                  <IconButton
                    icon={Pencil}
                    onClick={() => setEditorOpen(true)}
                    className="meeting-invitation-list-content-button"
                    aria-controls="form"
                    disabled={meeting.state === MeetingState.EmployeePreview}
                    title={
                      meeting.state === MeetingState.EmployeePreview
                        ? "Bearbeitung erst möglich, nachdem die Versammlung für Eigentümer freigegeben wurde (Button 'Für Eigentümer anzeigen')."
                        : ""
                    }
                  />
                )}
              </div>
            )}
            {isEditorOpen && (
              <InvitationLinkEditor
                setEditorOpen={setEditorOpen}
                onlineMeetingInformation={meeting.onlineMeetingInformation}
                meetingId={meeting.id}
              />
            )}
          </dd>
          <dt className="meeting-invitation-list-title">oder vor Ort:</dt>
          <dd className="meeting-invitation-list-content">
            {meeting?.location ? meeting.location : ""}
          </dd>
          <dt className="meeting-invitation-list-title">Tagesordnung:</dt>
          <dd className="meeting-invitation-list-content-small">
            {
              "diese und dazugehörige Beschlussanträge s. auf der Registerkarte "
            }
            <Link to="../">“Tagesordnung”</Link>
          </dd>
          <dt className="meeting-invitation-list-title">Abstimmen:</dt>
          <dd className="meeting-invitation-list-content-small">
            selbst über HOMECASE oder über die Hausverwaltung
          </dd>
        </dl>
      )}
      {!meeting && <InvitationSkeleton />}
      {!isFetching && !isEmployee && (
        <InvitationActions myRelatedParticipant={myRelatedParticipants[0]} />
      )}
    </div>
  );
}
