import React, {
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import Button from "../Button";

import "../../../styles/components/common/DialogV2.scss";
import { AnyObject } from "final-form";
import classNames from "classnames";
import CloseIcon from "../../icons/CloseIcon";
import IconButton from "../IconButton";

export default function ConfirmDialogV2({
  title,
  description,
  onConfirm,
  onCancel,
  cancelLabel = "Abbrechen",
  confirmLabel = "Bestätigen",
  confirmComponent,
  className,
  confirmDisabled,
  showCancelButton = true,
}: Props): ReactElement {
  const [isVisibleDialog, setVisibleDialog] = useState(false);
  useEffect(() => {
    setVisibleDialog(true);
  }, []);

  return (
    <div className="dialog-background-layer">
      <div
        className={classNames(
          "dialog-responsive",
          { show: isVisibleDialog },
          className,
        )}
        role="alertdialog"
        aria-modal
        aria-labelledby="dialog1Title"
        aria-describedby="dialog1Desc"
        data-testid="confirm-dialog"
      >
        <h3 className="dialog-responsive-title" id="dialog1Title">
          <IconButton
            onClick={onCancel}
            className="dialog-responsive-button-close"
            icon={CloseIcon}
            width={30}
            height={30}
          />

          <p className={"dialog-title"}> {title}</p>
        </h3>
        <div
          className="dialog-responsive-description"
          id="dialog1Desc"
          data-testid="description"
        >
          {description}
        </div>
        <div
          className={classNames("dialog-responsive-actions", {
            "cancel-button-hidden": !showCancelButton,
          })}
        >
          {showCancelButton && (
            <Button
              label={cancelLabel}
              onClick={onCancel}
              className="dialog-responsive-actions-cancel"
              data-testid="confirm-dialog-cancelButton"
            />
          )}
          {Array.isArray(confirmLabel) &&
            confirmLabel.map((label, key) => (
              <Button
                lightblue
                label={label}
                onClick={onConfirm}
                className="dialog-responsive-actions-confirm"
                aria-controls="form"
                id={`dialog-responsive-actions-confirm-${key}`}
                key={key}
                disabled={confirmDisabled}
              />
            ))}
          {!Array.isArray(confirmLabel) &&
            confirmLabel &&
            !confirmComponent && (
              <Button
                lightblue
                label={confirmLabel}
                onClick={onConfirm}
                className="dialog-responsive-actions-confirm"
                aria-controls="form"
                disabled={confirmDisabled}
                data-testid="confirm-dialog-confirmButton"
                id="dialog-actions-confirm-1"
              />
            )}
          {confirmComponent}
        </div>
      </div>
    </div>
  );
}

type Props = {
  description: ReactNode;
  title: ReactNode;
  onConfirm?: (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => void | Promise<void | AnyObject | undefined>;
  onCancel?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  confirmLabel?: string | string[];
  confirmComponent?: ReactNode | ReactNode[];
  cancelLabel?: string;
  className?: string;
  confirmDisabled?: boolean;
  showCancelButton?: boolean;
};
