import { Field, Form } from "react-final-form";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../common/Button";
import AlertDialog from "../../common/dialog/AlertDialog";
import { usePatchMeetingMutation } from "../../../api/meetingApi";
import "../../../styles/components/meetings/inivitation/InvitationLinkEditor.scss";
import { TextAreaForwardRef } from "../../common/form/TextArea";
import LinkifyIt from "linkify-it";
import InfoText from "../../userSettings/InfoText";

const linkify = new LinkifyIt();
const validateLink = (text: string) => {
  return linkify.test(text.trim())
    ? undefined
    : "Bitte geben Sie einen gültigen Link ein.";
};

export default function InvitationLinkEditor({
  setEditorOpen,
  meetingId,
  onlineMeetingInformation,
}: Props): JSX.Element {
  const [alertDialogHidden, setAlertDialogHidden] = useState(true);
  const [errorAlertDialogHidden, setErrorAlertDialogHidden] = useState(true);
  const { facilityObjectId = "", customerToken = "" } = useParams();
  const [patchMeeting, { isSuccess, isError, error }] =
    usePatchMeetingMutation();

  const editorDefaultContent = onlineMeetingInformation
    ? onlineMeetingInformation
    : "HOMECASE und Online Meeting (Link folgt von Ihrem Hausverwalter)";

  const onSubmit = useCallback(
    async (values: { editor: string }) => {
      const result = await patchMeeting({
        customerToken,
        facilityObjectId,
        meetingId: meetingId,
        onlineMeetingInformation: values.editor,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return result?.error;
    },
    [onlineMeetingInformation],
  );

  useEffect(() => {
    if (isSuccess) {
      setAlertDialogHidden(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error || isError) {
      setErrorAlertDialogHidden(false);
    }
  }, [error, isError]);

  const infoText = (
    <div className={"info-text"}>
      <span>
        Bitte fügen Sie hier den direkten Link zum Online-Meeting ein. Es darf
        ausschließlich ein einziger Link hinterlegt werden.
      </span>
      <span>
        Dieser Link wird den Teilnehmern sowohl in der Einladungsmail als auch
        auf HOMECASE während der laufenden Versammlung zur Verfügung gestellt
        und ermöglicht die direkte Teilnahme am Meeting.
      </span>
      <span>
        Hinweis: Bitte stellen Sie sicher, dass es sich um einen gültigen und
        funktionierenden Link handelt.
      </span>
    </div>
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ editor: editorDefaultContent }}
      render={({ handleSubmit, dirty, valid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name="editor"
              component={TextAreaForwardRef}
              initialValue={editorDefaultContent}
              parse={(value) => {
                return value.trim();
              }}
              validate={(value) => validateLink(value)}
              isLinkEditor={true}
            />

            <InfoText textInfo={infoText} isInfo={true} />

            <div className="invitation-link-editor-buttons-wrapper">
              <Button
                label="Abbrechen"
                type="button"
                onClick={() => setEditorOpen(false)}
              />
              <Button
                lightblue
                label="Speichern"
                type="submit"
                disabled={!valid || !dirty}
              />
            </div>
            {isSuccess && !alertDialogHidden && (
              <AlertDialog
                title={"Erfolgreich gespeichert."}
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => {
                  setAlertDialogHidden(true);
                  setEditorOpen(false);
                }}
              />
            )}
            {isError && !errorAlertDialogHidden && (
              <AlertDialog
                title={
                  "Die Änderungen konnten nicht gespeichert werden. Bitte versuchen Sie es erneut."
                }
                description={""}
                confirmLabel={"OK"}
                onConfirm={() => setErrorAlertDialogHidden(true)}
              />
            )}
          </form>
        );
      }}
    />
  );
}

type Props = {
  setEditorOpen: (item: boolean) => void;
  onlineMeetingInformation: string | null;
  meetingId: string;
};
