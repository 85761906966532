import useMeetingState from "../../../hooks/meetings/useMeetingState";
import {
  DialogType,
  MeetingState,
  ParticipantDelegationType,
} from "../../../enums";
import React, { Fragment, SyntheticEvent, useCallback, useState } from "react";
import {
  useGetMyInvitationQuery,
  useGetVoteInstructionsQuery,
} from "../../../api/meetingApi";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Button from "../../common/Button";
import { Participant, ParticipantsWithVoteInstructions } from "../../../types";
import InvitationState from "./InvitationState";
import InvitationAccept from "./InvitationAccept";
import InvitationDecline from "./InvitationDecline";
import InvitationDelegate from "./InvitationDelegate";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { isEmployeeByRoles } from "../../../lib";
import VotingInstructions from "./VotingInstructions";
import ParticipantsPageAlertDialogDescriptionInstructions from "../participants/ParticipantsPageAlertDialogDescriptionInstructions";
import useGetUsersMyProfile from "../../../hooks/useGetUsersMyProfile";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";

export default function InvitationActions({ myRelatedParticipant }: Props) {
  const { user } = useGetUsersMyProfile();
  const meetingState = useMeetingState();
  const userRoles = useCustomerRoles();
  const isEmployee = isEmployeeByRoles(userRoles);
  const [isDialogVoteInstructionsOpen, setDialogVoteInstructionsOpen] =
    useState(false);
  const [myInstructionsListDialogOpen, setMyInstructionsDialogOpen] =
    useState(false);
  const [dialog, setDialog] = useState<DialogType | null>(null);
  const [actionButtonText, setActionButtonText] = useState<string | null>("");
  const {
    meetingId = "",
    customerToken = "",
    facilityObjectId = "",
  } = useParams();
  const hasActions = ![MeetingState.Closed].includes(meetingState);

  const { data: myInvitation, isFetching } = useGetMyInvitationQuery(
    {
      customerToken,
      meetingId,
      facilityObjectId,
    },
    { skip: !hasActions || isEmployee },
  );

  const { data: myInstructions } = useGetVoteInstructionsQuery(
    {
      customerToken,
      meetingId,
      facilityObjectId,
    },
    { skip: isEmployee },
  );

  const onCancel = useCallback(() => setDialog(null), []);
  const showAccept = !myInvitation || !myInvitation.invitationAccepted;
  const showEditAccept =
    myInvitation && myInvitation.invitationAccepted === true;
  const showDecline = !myInvitation || myInvitation.invitationAccepted;
  const showEditDelegation =
    myInvitation &&
    !myInvitation.invitationAccepted &&
    myRelatedParticipant?.delegationType !== ParticipantDelegationType.None;
  const showAddDelegation =
    myInvitation &&
    !myInvitation.invitationAccepted &&
    myRelatedParticipant?.delegationType === ParticipantDelegationType.None;

  const myInstructionsInfo: ParticipantsWithVoteInstructions[] = [
    {
      firstName: user.firstName,
      lastName: user.lastName,
      voteDirective: myInstructions,
    },
  ];

  const handleSetDialog = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      setActionButtonText(e.currentTarget.textContent);
      const dialogType = e.currentTarget.value as unknown as DialogType;
      setDialog(Number(dialogType));
    },
    [],
  );

  const handleSetDialogVoteInstructionsOpen = useCallback(() => {
    setDialogVoteInstructionsOpen(true);
  }, []);

  const handleSetMyInstructionsDialogOpen = useCallback(() => {
    setMyInstructionsDialogOpen(false);
  }, []);

  const handleInstructionsInfo = useCallback(() => {
    setMyInstructionsDialogOpen(true);
  }, []);
  const handleInstructionsChanges = useCallback(() => {
    handleSetDialogVoteInstructionsOpen();
    handleSetMyInstructionsDialogOpen();
  }, []);
  return (
    <Fragment>
      <InvitationState
        myInvitation={myInvitation}
        myRelatedParticipant={myRelatedParticipant}
        instructions={myInstructions}
      />
      {isFetching && (
        <div className="meeting-invitation-actions">
          <Skeleton width={100} height={48} className="button" />
          <Skeleton width={100} height={48} className="button" />
        </div>
      )}
      {hasActions && !isFetching && (
        <div
          className="meeting-invitation-actions"
          data-testid="meeting-invitation-actions"
        >
          {(showAccept || showEditAccept) && (
            <Button
              label={showAccept ? "Zusagen" : "Zusage bearbeiten"}
              value={DialogType.accept}
              onClick={handleSetDialog}
              className={"button-accept"}
            />
          )}
          {showDecline && (
            <Button
              red
              label="Absagen"
              value={DialogType.decline}
              onClick={handleSetDialog}
            />
          )}
          {myInvitation?.invitationAccepted === false && (
            <span className={"separator-line"}></span>
          )}

          {(showEditDelegation || showAddDelegation) && (
            <Button
              label={
                showEditDelegation
                  ? "Vollmacht bearbeiten"
                  : "Vollmacht erteilen"
              }
              value={DialogType.delegate}
              onClick={handleSetDialog}
              className={"button-delegation"}
            />
          )}
          {myInvitation?.invitationAccepted === false &&
            !myInstructions?.instructions[0] && (
              <Button
                label="Weisungen erteilen"
                onClick={handleSetDialogVoteInstructionsOpen}
                className={"button-instructions"}
              />
            )}
          {!myInvitation?.invitationAccepted &&
            !isEmployee &&
            myInstructions?.instructions[0] && (
              <Button
                label="Erteilte Weisungen"
                onClick={handleInstructionsInfo}
                className={"button-instructions"}
              />
            )}
        </div>
      )}
      {dialog === DialogType.accept && myRelatedParticipant && (
        <InvitationAccept
          onCancel={onCancel}
          hasExistsDelegation={Boolean(showEditDelegation)}
          myRelatedParticipant={myRelatedParticipant}
          isEmployee={isEmployee}
          myInvitation={myInvitation}
        />
      )}
      {dialog === DialogType.decline && (
        <InvitationDecline onCancel={onCancel} isEmployee={isEmployee} />
      )}
      {dialog === DialogType.delegate && myRelatedParticipant && (
        <InvitationDelegate
          myRelatedParticipant={myRelatedParticipant}
          onCancel={onCancel}
          actionButtonText={actionButtonText}
        />
      )}
      {isDialogVoteInstructionsOpen && (
        <VotingInstructions
          isDialogOpen={isDialogVoteInstructionsOpen}
          setDialogOpen={setDialogVoteInstructionsOpen}
        />
      )}

      {myInstructionsListDialogOpen && (
        <ConfirmDialog
          description={
            <ParticipantsPageAlertDialogDescriptionInstructions
              instructions={myInstructionsInfo}
            />
          }
          cancelLabel={"Schließen"}
          onCancel={handleSetMyInstructionsDialogOpen}
          confirmLabel="Weisungen bearbeiten"
          title="Erteilte Weisungen"
          onConfirm={handleInstructionsChanges}
          showCloseButton={true}
        />
      )}
    </Fragment>
  );
}

type Props = {
  myRelatedParticipant?: Participant;
};
