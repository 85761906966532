import { useParams } from "react-router-dom";
import {
  useCreateOnlineIdentityForTenantMutation,
  useIssueNewRegistrationCodeForOnlineIdentityMutation,
} from "../api/facilityManagementApi";
import { useCallback, useState } from "react";
import { StakeholdersInfo } from "../types";

export default function useCreateNewCode(
  isActivePropertyStakeholder: StakeholdersInfo[],
): Props {
  const { customerToken = "" } = useParams();
  const [
    createOnlineIdentityForTenant,
    { isLoading: isLoadingOnlineIdentityForTenant },
  ] = useCreateOnlineIdentityForTenantMutation();

  const [
    issueNewRegistrationCodeForOnlineIdentity,
    { isLoading: isLoadingNewRegistrationCodeForOnlineIdentity },
  ] = useIssueNewRegistrationCodeForOnlineIdentityMutation();

  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const handleCreateNewCode = useCallback(async () => {
    setError(null);
    setIsSuccess(false);

    const responses = await Promise.all(
      isActivePropertyStakeholder.map(async (stakeholder) => {
        const response = await createOnlineIdentityForTenant({
          customerToken,
          tenantId: stakeholder.addressId,
          issuedFor: `${stakeholder.firstName} ${stakeholder.lastName}`,
        });

        if (response && "data" in response) {
          await issueNewRegistrationCodeForOnlineIdentity({
            customerToken,
            oid: response.data.oid,
          });
          return { success: true };
        } else if (response && "error" in response) {
          return { success: false, error: response.error };
        }
      }),
    );

    const hasError = responses.some((res) => res && !res.success);
    if (hasError) {
      setError("Einige Anfragen sind fehlgeschlagen.");
    } else {
      setIsSuccess(true);
    }
  }, [
    isActivePropertyStakeholder,
    createOnlineIdentityForTenant,
    issueNewRegistrationCodeForOnlineIdentity,
  ]);

  return {
    handleCreateNewCode,
    error,
    isSuccess,
    isLoadingOnlineIdentityForTenant,
    isLoadingNewRegistrationCodeForOnlineIdentity,
  };
}

type Props = {
  handleCreateNewCode: () => void;
  error: string | null;
  isSuccess: boolean;
  isLoadingOnlineIdentityForTenant: boolean;
  isLoadingNewRegistrationCodeForOnlineIdentity: boolean;
};
