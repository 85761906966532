import React, { ReactElement } from "react";

import "../../styles/components/userSettings/InfoText.scss";
import classNames from "classnames";

export default function InfoText({
  textSuccess,
  textError,
  isSuccess,
  isError,
  isInfo,
  textInfo,
  className,
}: Props): JSX.Element {
  return (
    <section
      className={classNames("info-text-wrapper", {
        className,
      })}
    >
      {isSuccess && <div className="text-info success">{textSuccess}</div>}
      {isError && <div className="text-info error">{textError}</div>}
      {isInfo && <div className="text-info info">{textInfo}</div>}
    </section>
  );
}

type Props = {
  textInfo?: string | ReactElement;
  textSuccess?: string;
  textError?: string;
  isSuccess?: boolean;
  isError?: boolean;
  isInfo?: boolean;
  className?: string;
};
