import { FieldRenderProps } from "react-final-form";
import {
  forwardRef,
  ReactElement,
  Ref,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
} from "react";
import classNames from "classnames";

import "../../../styles/components/common/form/Textarea.scss";

export default function TextArea(
  {
    input,
    meta,
    className,
    label,
    required,
    onChange,
    isLinkEditor = false,
    ...rest
  }: FieldRenderProps<string | undefined, HTMLTextAreaElement>,
  ref: Ref<HTMLTextAreaElement>,
): ReactElement {
  const textareaClassName = classNames(className, {
    "textarea-field": true,
    "textarea-error": (meta.error || meta.submitError) && meta.touched,
    "textarea-focus": meta.active,
  });

  const internalRef = useRef<HTMLTextAreaElement>(null);
  const textareaRef = ref && "current" in ref ? ref : internalRef;

  const adjustHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    adjustHeight();
  }, [input.value]);

  useEffect(() => {
    const handleResize = () => {
      adjustHeight();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onChangeIntern = useCallback(
    (event: SyntheticEvent<HTMLTextAreaElement>) => {
      const rawValue = event.currentTarget?.value || "";
      const value = isLinkEditor ? rawValue.replace(/\s+/g, "") : rawValue;

      input.onChange(isLinkEditor && value.length === 1 ? value.trim() : value);

      adjustHeight();
      if (onChange) {
        onChange(event);
      }
    },
    [input.onChange, adjustHeight, onChange],
  );

  return (
    <section className={textareaClassName}>
      <label>
        {label && (
          <span className="textarea-field-label">
            {label} {required && "*"}
          </span>
        )}
        <textarea
          {...input}
          {...rest}
          onChange={onChangeIntern}
          ref={textareaRef}
          required={required}
          data-testid="textarea"
        />
      </label>
      {(meta.error || meta.submitError) && meta.touched && (
        <div className="textarea-field-error">
          {meta.error || meta.submitError}
        </div>
      )}
    </section>
  );
}

export const TextAreaForwardRef = forwardRef<
  HTMLTextAreaElement,
  FieldRenderProps<string, HTMLTextAreaElement>
>(TextArea);
