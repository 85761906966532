export enum ActivityState {
  NotDefined = 0,
  Open = 1,
  InProgress = 2,
  Closed = 3,
}
export enum ActivityType {
  General = 1,
  Object = 2,
}

export enum ActivityCustomFieldType {
  Text = 1,
  Number = 2,
  ShortDate = 3,
  DateYear = 4,
  Currency = 5,
  ItemList = 6,
  Iban = 8,
}

export enum MeetingState {
  Unknown = -1,
  EmployeePreview = 0,
  OwnerAndEmployeePreview = 1,
  Running = 2,
  Closed = 3,
}

export enum AttendanceType {
  Absent = 0,
  Locally = 2,
  Online = 1,
}

export enum VoteCastingType {
  None = 0,
  ByHomecase = 1,
  ByEmployee = 2,
}

export enum ServiceProviderCommunicationDataType {
  Telefon = 1,
  Fax = 2,
  Email = 3,
  Website = 4,
}

export enum MeetingType {
  Ordinary = 1,
  Extraordinary = 2,
  CircularDecision = 3,
}

export enum MeetingAttendConfirmed {
  IsToConfirm = 0,
  InConfirmation = 1,
}
export enum MeetingLeaveConfirmed {
  IsToConfirm = 0,
  InConfirmation = 1,
}

export enum ValidateMeetingDataIntegrityFailureReason {
  None = 0,
  MeetingNotFound = 1,
  AgendaItemsListIsEmpty = 2,
  AgendaItemsWithoutTitle = 3,
  PropositionsWithoutQualificationVotingRule = 4,
  PropositionsWithoutQualificationDistributionKeyId = 5,
  PropositionsWithoutDoubleQualificationVotingRule = 6,
  PropositionsWithoutDoubleQualificationDistributionKeyId = 7,
  PropositionsWithoutPropositionContentAndDecision = 8,
  PropositionsWithoutTitle = 9,
}

export enum AgendaItemType {
  WithoutSpecificPurpose = 1,
  ParticipantsRegistration = 2,
  AdvisoryElection = 3,
  OrganizationalResolution = 4,
}

export enum PropositionVotingType {
  Simple = 1,
  Detailed = 2,
  ByArea = 3,
  NotDefined = 4,
}

export enum PropositionVotingPrincipleType {
  HeadPrinciple = 0,
  ValuePrinciple = 1,
  ObjectPrinciple = 2,
}

export enum PropositionVotingState {
  None = 0,
  Running = 1,
  Closed = 2,
}

export enum PropositionVoteType {
  None = 0,
  Yes = 1,
  No = 2,
  Abstention = 3,
}

export enum PropositionEffectivenessType {
  NotDefined = 0,
  Limited = 1,
  Permanent = 2,
}

export enum PropositionLegalValidityType {
  None = -1,
  NotValid = 0,
  Valid = 1,
}

export enum PropositionAcceptanceStatus {
  None = -1,
  NotAccepted = 0,
  Accepted = 1,
}

export enum FacilityObjectType {
  Tenantment = 1,
  ResidentialCommunityOfOwners = 2,
  PartOwnership = 3,
  SocialHousing = 4,
  Other = 5,
  CommercialProperty = 6,
  ObjectSurfaceModel = 7,
  CentralTenant = 8,
}

export enum UserRoles {
  Employee = "Employee",
  Tenant = "Tenant",
  ServiceProvider = "ServiceProvider",
  ObjectOwner = "ObjectOwner",
  Owner = "Owner",
}

export enum ParticipantAttendanceType {
  Abscent = 0,
  Online = 1,
  Locally = 2,
}

export enum ParticipantDelegationType {
  None = 0,
  VotingEligibilityDelegatedToParticipant = 1,
  VotingEligibilityDelegatedToEmployee = 2,
}

export enum SynchronisationMethod {
  JoinMeeting = "JoinMeeting",
  LeaveMeeting = "LeaveMeeting",
  MeetingStateChanged = "MeetingStateChanged",
  ParticipantsChanged = "ParticipantsChanged",
  PropositionVotingStateChanged = "PropositionVotingStateChanged",
  PropositionVotingChanged = "PropositionVotingChanged",
  PropositionChanged = "PropositionChanged",
  JoinPublicChannelOfFacilityObject = "JoinPublicChannelOfFacilityObject",
  LeavePublicChannelOfFacilityObject = "LeavePublicChannelOfFacilityObject",
  AgendaItemChanged = "AgendaItemChanged",
}

export enum UploadState {
  Ready = 0,
  InProgress = 1,
  Completed = 2,
  Failure = 3,
}

export enum ActivityDocumentSharedByMailDispatchingSharingType {
  ActivityParticipantRelation = 0,
  FacilityObjectOnlineDocumentContractualRelation = 1,
}

export enum PreSelectedDocumentListType {
  ObjectDocuments = "objektDokumente",
  PersonalDocuments = "persoenlicheDokumente",
}

export enum DocumentsQueryType {
  FacilityObjectDocuments = 1,
  SelectedTenancyOrOwnershipDocuments = 2,
  CurrentUserDocuments = 3,
  FacilityObjectDocumentsForManagement = 4,
}
export enum UserEventType {
  BulletinBoardPostCreatedUserEvent = "BulletinBoardPostCreatedUserEvent",
  BulletinBoardPostCommentCreatedUserEvent = "BulletinBoardPostCommentCreatedUserEvent",
  ActivityCreatedUserEvent = "ActivityCreatedUserEvent",
  ActivitySetOnlineUserEvent = "ActivitySetOnlineUserEvent",
  ActivityStateChangedUserEvent = "ActivityStateChangedUserEvent",
  ContactCreatedUserEvent = "ContactCreatedUserEvent",
  ContactSetOnlineUserEvent = "ContactSetOnlineUserEvent",
  FacilityDocumentRolePermissionsGrantedUserEvent = "FacilityObjectOnlineDocumentPermissionsGrantedUserEvent",
  FacilityDocumentPersonPermissionsGrantedUserEvent = "FacilityObjectOnlineDocumentPersonPermissionsGrantedUserEvent",
  FacilityDocumentContractualRelationPermissionsGrantedUserEvent = "FacilityObjectOnlineDocumentContractualRelationPermissionsGrantedUserEvent",
  AuditIsReadyToReviewUserEvent = "AuditIsReadyToReviewUserEvent",
  AuditReviewRetryUserEvent = "AuditReviewRetryUserEvent",
  MeetingSetOnlineEvent = "MeetingSetOnlineEvent",
  MeetingStartedEvent = "MeetingStartedEvent",
  MeetingDelegatedVotingSetToParticipantEvent = "MeetingDelegatedVotingSetToParticipantEvent",
  ActivityDocumentsSharingByMailDispatchingCompletedUserEvent = "ActivityDocumentsSharingByMailDispatchingCompletedUserEvent",
}

export enum TermsOfServicesState {
  Draft = 1,
  Actual = 2,
  Archived = 3,
}

export enum TermsOfServicesUsersSortBy {
  None = 0,
  UserName = 1,
  LastAcceptedVersion = 2,
  AcceptedAtUtc = 3,
}
export enum CommunicationDataType {
  Phone = 1,
  Fax = 2,
  Email = 3,
  WebSite = 4,
}

export enum FacilityObjectQueryFilterType {
  PastManaged = "managedInThePast",
  CurrentlyManaged = "currentlyManaged",
  FutureManaged = "managedInTheFuture",
  Online = "online",
}

export enum PictureFillStrategy {
  None = 0,
  Fill = 1,
  Fit = 2,
  FillAndConvert = 3,
  FitAndConvert = 4,
}

export enum GroupingType {
  None = "Keine Gruppierung",
  DocumentCategory = "DocumentCategory",
  Date = "Datum",
}

export enum FacilityObjectListSortingType {
  None = "Keine Sortierung",
  Alphabetical = "Alphabetisch",
  Date = "Erstellungsdatum",
  Postcode = "PLZ",
  ObjectType = "Objekttyp",
}
export enum ParticipantsPurposeType {
  ForVote = 1,
  Voted = 2,
  NotVoted = 3,
  Absent = 4,
}

export enum DialogType {
  "accept" = 0,
  "decline" = 1,
  "delegate" = 2,
}

export enum LogEventType {
  MeetingStarted = "MeetingStarted",
  MeetingEnded = "MeetingEnded",
  PropositionVotingStarted = "PropositionVotingStarted",
  PropositionVotingEnded = "PropositionVotingEnded",
  ParticipantDelegatedVotingEligibility = "ParticipantDelegatedVotingEligibility",
  ParticipantsAttendanceRegisteredByEmployee = "ParticipantsAttendanceRegisteredByEmployee",
  ParticipantAttendedMeetingInHomecase = "ParticipantAttendedMeetingInHomecase",
  ParticipantLeftMeetingInHomecase = "ParticipantLeftMeetingInHomecase",
  VotingEligibilityChangedByEmployee = "VotingEligibilityChangedByEmployee",
  PropositionVotedByParticipant = "PropositionVotedByParticipant",
  PropositionVotedByParticipantForAnotherParticipant = "PropositionVotedByParticipantForAnotherParticipant",
  PropositionVotedByEmployeeForParticipant = "PropositionVotedByEmployeeForParticipant",
  PropositionVotingResultUpdated = "PropositionVotingResultUpdated",
  PropositionDecisionHasLegalForceUpdated = "PropositionDecisionHasLegalForceUpdated",
  PropositionStatusUpdated = "PropositionStatusUpdated",
  PropositionCategoryUpdated = "PropositionCategoryUpdated",
  VoteInstructionsForPropositionInvalidated = "VoteInstructionsForPropositionInvalidated",
  PropositionTitleUpdated = "PropositionTitleUpdated",
  PropositionVotingQualificationRulesUpdated = "PropositionVotingQualificationRulesUpdated",
}

export enum BulletinBoardPostPinningType {
  AffectAllRelatedBulletinBoards = 1,
  AffectOnlyCurrentBulletinBoard = 2,
}

export enum BulletinBoardPostUpdateTenantCommentingAbilityType {
  AffectAllRelatedBulletinBoards = 1,
  AffectOnlyCurrentBulletinBoard = 2,
}

export enum OnlineDocumentPermissionType {
  ObjectOwnerCanSee = "ObjectOwnerCanSee",
  TenantCanSee = "TenantCanSee",
  ServiceProviderCanSee = "ServiceProviderCanSee",
}

export enum DcsEmployeePermissionType {
  CanCreateRegCodes = 1,
  CanCreateEmployeeAccount = 2,
  CanSeeAllFacilityObjects = 3,
  CanManageBulletinBoard = 4,
  CanManageTenant = 5,
  CanManageEmployeePermissions = 6,
  CanModifyCustomerMasterData = 7,
  CanModifyFacilityObjectMasterData = 8,
  CanModerateBulletinBoards = 9,
  CanDeletePersonDependencies = 10,
  CanDeleteActivityDependencies = 11,
  CanManageVoucherAudits = 12,
  CanManageTermsOfServices = 13,
  CanCreateFluksApiKey = 14,
}

export enum LOGO_SRC_SET {
  "180x45" = "1x",
  "360x90" = "2x",
  "540x130" = "3x",
}
