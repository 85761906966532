import React, {
  Fragment,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StakeholdersInfo } from "../../../types";
import "../../../styles/components/management/objectSettings/ServiceProviderListItem.scss";
import Menu from "../../common/Menu";
import MenuListItem from "../../common/menu/MenuListItem";
import MenuListDiver from "../../common/menu/MenuListDiver";
import CrossIcon from "../../icons/CrossIcon";
import DownloadIcon from "../../icons/DownloadIcon";
import Send from "../../icons/Send";
import SendInvitationDialogDescription from "./SendInvitationDialogDescription";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";
import { useSendRegistrationCodeByEmailMutation } from "../../../api/facilityManagementApi";
import { Form } from "react-final-form";
import { useParams } from "react-router-dom";
import AlertDialog from "../../common/dialog/AlertDialog";
import IconButton from "../../common/IconButton";
import ThreeDots from "../../icons/ThreeDots";
import useCreateNewCode from "../../../hooks/useCreateNewCode";
import Checkbox from "../../common/form/Checkbox";
import useStakeholdersRegistration from "../../../hooks/management/useStakeholdersRegistration";

export default function StakeholdersInfoListItem({
  stakeholdersInfo,
  setActiveStakeholder,
  setSelectedTenants,
  selectedTenants,
  isPermissionCanManageTenant,
}: Props): ReactElement {
  const [sendRegistrationCodeByEmail] =
    useSendRegistrationCodeByEmailMutation();
  const { customerToken = "" } = useParams();

  const [isSendInvitationDialogOpen, setSendInvitationDialog] = useState(false);

  const handleSetInvitationDialog = () => {
    setSendInvitationDialog(true);
  };

  const [isSubmitErrorsText, setSubmitErrorsText] = useState(false);
  const [isSubmitDialogOpen, setSubmitDialog] = useState(false);
  const [isCodeError, setCodeError] = useState(false);
  const [isCodeNewCodeDialog, setNewCodeDialog] = useState(false);
  const handleFormSubmit = useCallback(async ({ eMail }: { eMail: string }) => {
    const response = await sendRegistrationCodeByEmail({
      customerToken,
      personId: stakeholdersInfo.addressId,
      email: eMail,
      issuedFor: stakeholdersInfo.firstName + " " + stakeholdersInfo.lastName,
    });
    setSendInvitationDialog(false);
    if ("data" in response) {
      const isSuccess = response.data;
      if (isSuccess) {
        setSubmitErrorsText(false);
        setSubmitDialog(true);
      }
    } else if ("error" in response) {
      setSubmitErrorsText(true);
      setSubmitDialog(false);
    }
  }, []);

  const {
    handleGetInfoUsersAndCode,
    exportToCSV,
    isLoading,
    registrationData,
  } = useStakeholdersRegistration();

  const handleSetActiveItem = useCallback(async () => {
    setActiveStakeholder(stakeholdersInfo);
  }, [registrationData]);

  const button = <IconButton icon={ThreeDots} />;

  const { handleCreateNewCode, error, isSuccess } = useCreateNewCode([
    stakeholdersInfo,
  ]);

  useEffect(() => {
    if (error) {
      setCodeError(true);
    } else if (isSuccess) {
      setNewCodeDialog(true);
    }
  }, [error, isSuccess]);

  useEffect(() => {
    if (!isLoading && registrationData) {
      exportToCSV();
    }
  }, [registrationData, isLoading]);

  const handleOnChange = useCallback(
    async (e: SyntheticEvent<HTMLInputElement>) => {
      const { checked } = e.currentTarget;
      setSelectedTenants((prevSelected: StakeholdersInfo[]) => {
        if (checked) {
          return [...prevSelected, stakeholdersInfo];
        } else {
          return prevSelected.filter(
            (tenant) => tenant.addressId !== stakeholdersInfo.addressId,
          );
        }
      });
    },
    [stakeholdersInfo, selectedTenants],
  );

  const checkboxInfo = useMemo(() => {
    return selectedTenants.some(
      (item) => item.addressId === stakeholdersInfo.addressId,
    );
  }, [selectedTenants, stakeholdersInfo.addressId]);

  const handleExportToCSV = useCallback(() => {
    handleGetInfoUsersAndCode(stakeholdersInfo);
  }, [stakeholdersInfo]);
  return (
    <Fragment>
      <div role="row" className="service-provider-list-item">
        {isPermissionCanManageTenant && (
          <Checkbox
            label={""}
            onChange={handleOnChange}
            checked={checkboxInfo}
          />
        )}

        <div
          className="name-wrapper"
          data-testid="stakeholder-item"
          onClick={handleSetActiveItem}
        >
          <div role="cell">
            <div>
              {stakeholdersInfo.lastName} {stakeholdersInfo.firstName}
            </div>
          </div>
          {stakeholdersInfo.activeAccountsCount > 0 && (
            <span
              className="registered-accounts-count"
              data-testid="registered-accounts-count"
            >
              {stakeholdersInfo.activeAccountsCount}
            </span>
          )}
        </div>

        <Menu button={button} className="provider-item-menu">
          <MenuListItem
            leadingIcon={<CrossIcon className="menu-icon" />}
            onClick={handleCreateNewCode}
          >
            Registrierungscode erstellen
          </MenuListItem>
          <MenuListDiver />
          <MenuListItem
            leadingIcon={<Send className="menu-icon" />}
            onClick={handleSetInvitationDialog}
          >
            Einladung senden
          </MenuListItem>

          <MenuListDiver />
          <MenuListItem
            leadingIcon={<DownloadIcon className="menu-icon" />}
            onClick={handleExportToCSV}
          >
            Registrierungsdaten exportieren
          </MenuListItem>
          <MenuListDiver />
        </Menu>

        {isSendInvitationDialogOpen && (
          <Form
            onSubmit={handleFormSubmit}
            initialValues={{
              eMail: "",
            }}
            render={({ handleSubmit }) => {
              return (
                <ConfirmDialog
                  description={
                    <SendInvitationDialogDescription
                      personId={stakeholdersInfo.addressId}
                      isSubmitErrorsText={isSubmitErrorsText}
                    />
                  }
                  confirmLabel="Senden"
                  title="Einladung versenden"
                  onConfirm={handleSubmit}
                  cancelLabel="Abbrechen"
                  onCancel={() => setSendInvitationDialog(false)}
                />
              );
            }}
          />
        )}

        {isSubmitErrorsText && !isSendInvitationDialogOpen && (
          <AlertDialog
            title="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
            onConfirm={() => setSubmitErrorsText(false)}
            description=""
          />
        )}

        {!isSubmitErrorsText &&
          !isSendInvitationDialogOpen &&
          isSubmitDialogOpen && (
            <AlertDialog
              title="Einladung versenden"
              onConfirm={() => setSubmitDialog(false)}
              description="Die Einladung wurde erfolgreich versendet."
            />
          )}

        {isCodeError && (
          <AlertDialog
            title="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
            onConfirm={() => setCodeError(false)}
            description=""
          />
        )}

        {isCodeNewCodeDialog && (
          <AlertDialog
            title="Registrierungscode wurde erstellt."
            onConfirm={() => setNewCodeDialog(false)}
            description=""
          />
        )}
      </div>
    </Fragment>
  );
}

type Props = {
  stakeholdersInfo: StakeholdersInfo;
  isActive?: boolean;
  setActiveStakeholder: (stakeholderInfo: StakeholdersInfo) => void;
  setSelectedTenants: React.Dispatch<React.SetStateAction<StakeholdersInfo[]>>;
  selectedTenants: StakeholdersInfo[];
  isPermissionCanManageTenant: boolean;
};
