import React, { useMemo } from "react";
import { Field } from "react-final-form";
import CheckboxField from "../../common/form/CheckboxField";
import "../../../styles/components/meetings/proposition/PropositionVotingResolutionDialogInfo.scss";
import Select from "../../common/form/Select";
import {
  useGetPropositionCategoriesQuery,
  useGetPropositionStatusesQuery,
} from "../../../api/propositionApi";
import { useParams } from "react-router-dom";

export default function PropositionVotingResolutionDialogInfo(): JSX.Element {
  const {
    customerToken = "",
    facilityObjectId = "",
    meetingId = "",
  } = useParams();

  const { data: propositionCategories = [] } = useGetPropositionCategoriesQuery(
    {
      customerToken,
      facilityObjectId,
      meetingId,
    },
  );

  const propositionCategoryOptions = useMemo(
    () =>
      propositionCategories
        .map(({ id, title }) => ({
          label: title,
          value: id,
        }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [propositionCategories],
  );

  const { data: propositionStatuses = [] } = useGetPropositionStatusesQuery({
    customerToken,
    facilityObjectId,
    meetingId,
  });

  const propositionStatusOptions = useMemo(
    () =>
      propositionStatuses
        .map(({ id, title }) => ({
          label: title,
          value: id,
        }))
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    [propositionStatuses],
  );

  return (
    <section className="proposition-voting-resolution-form-fields">
      <Field
        name="finalDecision"
        component={CheckboxField}
        type="checkbox"
        label="Beschluss gefasst"
        id="finalDecision"
      />
      <Field
        name="decisionCalm"
        component={CheckboxField}
        type="checkbox"
        label="Beschluss rechtskräftig"
        id="decisionCalm"
      />
      <Field
        name="propositionStatusId"
        component={Select}
        type="select"
        label="Status"
        id="propositionStatusId"
        options={propositionStatusOptions}
      />
      <Field
        name="propositionCategoryId"
        component={Select}
        type="select"
        label="Kategorie"
        id="propositionCategoryId"
        options={propositionCategoryOptions}
      />
    </section>
  );
}
