import React, { Fragment, useCallback, useMemo } from "react";
import "../../../styles/components/management/objectSettings/ObjectReports.scss";
import {
  useGetFacilityObjectReportsQuery,
  useIsReportsPeriodToLastYearRestrictedQuery,
  useRemoveReportsPeriodRestrictionMutation,
  useRestrictReportsPeriodToLastYearMutation,
} from "../../../api/facilityManagementApi";
import { useParams } from "react-router-dom";
import SwitchInput from "../../common/SwitchInput";
import ObjectReportItem from "./ObjectReportItem";
import useGetPermissions from "../../../hooks/useGetPermissions";
import ObjectReportItemSkeleton from "../../skeleton/ObjectReportItemSkeleton";
import { DcsEmployeePermissionType } from "../../../enums";

export default function ObjectReports(): JSX.Element {
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const {
    data: facilityObjectReports,
    isSuccess,
    isFetching,
  } = useGetFacilityObjectReportsQuery({
    customerToken,
    facilityObjectId,
  });

  const permissions = useGetPermissions();

  const isCanModifyFacilityObjectMasterData = useMemo(() => {
    return permissions.some(
      (permission) =>
        permission.pid ===
        DcsEmployeePermissionType.CanModifyFacilityObjectMasterData,
    );
  }, [permissions]);

  const { data: isReportsPeriodToLastYearRestrictedQuery } =
    useIsReportsPeriodToLastYearRestrictedQuery({
      customerToken,
      facilityObjectId,
    });

  const [removeReportsPeriodRestrictionMutation] =
    useRemoveReportsPeriodRestrictionMutation();
  const [restrictReportsPeriodToLastYearMutation] =
    useRestrictReportsPeriodToLastYearMutation();

  const handleSwitchInput = useCallback(async () => {
    if (isReportsPeriodToLastYearRestrictedQuery) {
      await removeReportsPeriodRestrictionMutation({
        facilityObjectId,
        customerToken,
      });
    } else if (!isReportsPeriodToLastYearRestrictedQuery) {
      await restrictReportsPeriodToLastYearMutation({
        facilityObjectId,
        customerToken,
      });
    }
  }, [isReportsPeriodToLastYearRestrictedQuery]);

  return (
    <section className="object-reports-wrapper">
      <div className="object-reports-container">
        <div className="info-text">
          Mitarbeiter der Hausverwaltung sehen alle Berichte ohne Einschränkung
          in der Zeitraumauswahl. Andere Nutzer nur freigegebene.
        </div>
        <div className="reports-descriptions-list">
          {facilityObjectReports
            ?.slice()
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((report) => (
              <ObjectReportItem
                key={report.id}
                report={report}
                isCanModifyFacilityObjectMasterData={
                  isCanModifyFacilityObjectMasterData
                }
              />
            ))}

          {isFetching && !isSuccess && (
            <Fragment>
              <ObjectReportItemSkeleton />
              <ObjectReportItemSkeleton />
              <ObjectReportItemSkeleton />
              <ObjectReportItemSkeleton />
            </Fragment>
          )}
        </div>

        <span className="list-border"></span>
        <SwitchInput
          id="push"
          label="Zeitraumauswahl für Berichte"
          defaultChecked={isReportsPeriodToLastYearRestrictedQuery}
          infoText="Möchten Sie die Zeitraumauswahl der Berichte bis letztes Jahr beschränken?"
          onClick={handleSwitchInput}
          disabled={!isCanModifyFacilityObjectMasterData}
        />
      </div>
    </section>
  );
}
