import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Linkify from "react-linkify";
import { AgendaItem } from "../../../types";
import Details from "../../common/Details";
import { useParams } from "react-router-dom";
import { useGetPropositionsQuery } from "../../../api/propositionApi";
import AgendaItemProposition from "./AgendaItemProposition";
import PropositionSummary from "../proposition/PropositionSummary";
import FetchApiError from "../../common/FetchApiError";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import useMeetingState from "../../../hooks/meetings/useMeetingState";

import { usePrevious } from "../../../hooks/usePrevious";
import { useSynchronisation } from "../../../hooks/useSynchronisation";
import { MeetingState, SynchronisationMethod } from "../../../enums";

import "../../../styles/components/meetings/agendaItem/AgendaItemDetails.scss";
import usePageVisibilityEffect from "../../../hooks/usePageVisibilityEffect";
import AgendaItemUpdateForm from "./AgendaItemUpdateForm";
import LightboxProvider from "../../common/lightbox/LightboxProvider";
import Lightbox from "../../common/lightbox/Lightbox";

function linkifyComponentDecorator(
  decoratedHref: string,
  decoratedText: string,
  key: number,
): JSX.Element {
  return (
    <a target="_blank" href={decoratedHref} key={key}>
      {decoratedText}
    </a>
  );
}

export default function AgendaItemDetails({
  item,
  showAttachments = false,
}: Props): JSX.Element {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
  } = useParams();

  const {
    data = [],
    isFetching,
    isError,
    refetch,
    error,
  } = useGetPropositionsQuery(
    {
      customerToken,
      facilityObjectId,
      meetingId,
      agendaItemId: item.id,
    },
    { skip: !(item.propositions === undefined || item.propositions === null) },
  );

  const propositions = item.propositions ?? data;

  const roles = useCustomerRoles();
  const meetingState = useMeetingState();
  const { connection } = useSynchronisation();
  const prevAgendaItemId = usePrevious(item.id, !isFetching);
  const shouldRenderSkeleton = prevAgendaItemId !== item.id;

  useEffect(() => {
    if (connection) {
      connection.on(
        SynchronisationMethod.PropositionVotingStateChanged,
        refetch,
      );
      connection.on(SynchronisationMethod.PropositionChanged, refetch);
    }
    return () => {
      if (connection) {
        connection.off(SynchronisationMethod.PropositionVotingStateChanged);
        connection.off(SynchronisationMethod.PropositionChanged);
      }
    };
  }, [connection]);

  usePageVisibilityEffect((visibilityState) => {
    if (
      meetingState === MeetingState.Running &&
      visibilityState === "visible"
    ) {
      refetch();
    }
  }, []);

  return (
    <LightboxProvider options={{ closeButton: true, showMeta: true }}>
      <div className="agenda-item-details" data-testid="agenda-item-details">
        {item.description && (
          <Details summary="Beschreibung" open controlId={item.id}>
            <Linkify componentDecorator={linkifyComponentDecorator}>
              <AgendaItemUpdateForm
                roles={roles}
                item={item}
                initialValues={{ description: item.description }}
              />
            </Linkify>
          </Details>
        )}
        {!shouldRenderSkeleton &&
          propositions.length > 0 &&
          propositions.map((proposition, key) => (
            <Details
              summary={
                <PropositionSummary
                  additionNumber={propositions.length > 1 ? key + 1 : 0}
                  title={proposition.title}
                  roles={roles}
                  meetingState={meetingState}
                  votingState={proposition.votingState}
                  id={proposition.id}
                  agendaItemType={item.type}
                />
              }
              open={key === 0}
              key={key}
              controlId={proposition.id}
            >
              <AgendaItemProposition
                meetingState={meetingState}
                proposition={proposition}
                agendaItemId={item.id}
                roles={roles}
                meetingId={meetingId}
                typeInfo={item.type}
                showAttachments={showAttachments}
              />
            </Details>
          ))}
        {shouldRenderSkeleton && (
          <Details
            controlId={"skeleton"}
            summary={<Skeleton className="agenda-item-details-skeleton" />}
          >
            <Skeleton count={3} />
          </Details>
        )}
        {isError && <FetchApiError error={error} />}
      </div>
      <Lightbox />
    </LightboxProvider>
  );
}

type Props = {
  item: AgendaItem;
  showAttachments?: boolean;
};
