import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import "../../../styles/components/management/objectSettings/ObjectOwner.scss";
import TableSortHeader from "../../common/TableSortHeader";
import StakeholdersInfoListItem from "./StakeholdersInfoListItem";
import { useParams } from "react-router-dom";
import useTableSort from "../../../hooks/common/useTableSort";
import { useGetFacilityObjectOwnersQuery } from "../../../api/facilityManagementApi";
import InfoText from "../../userSettings/InfoText";
import { StakeholdersInfo } from "../../../types";
import StakeholdersInfoListItemUsersAndCode from "./StakeholdersInfoListItemUsersAndCode";
import CaretDown from "../../icons/CaretDown";
import StakeholdersInfoListItemSkeleton from "../../skeleton/StakeholdersInfoListItem";
import useSortStakeholders from "../../../hooks/useSortStakeholders";
import useStakeholdersRegistration from "../../../hooks/management/useStakeholdersRegistration";
import Checkbox from "../../common/form/Checkbox";
import Button from "../../common/Button";
import DownloadIcon from "../../icons/DownloadIcon";
import CrossIcon from "../../icons/CrossIcon";
import useCreateNewCode from "../../../hooks/useCreateNewCode";
import AlertDialog from "../../common/dialog/AlertDialog";
import useGetPermissions from "../../../hooks/useGetPermissions";
import UpdateDialog from "../../common/dialog/UpdateDialog";

export default function ObjectOwner(): ReactElement {
  const permission = useGetPermissions();
  const isPermissionCanManageTenant = permission.some(
    (permission) => permission.pid === 5,
  );
  const { customerToken = "", facilityObjectId = "" } = useParams();
  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setSuccessDialogOpen] = useState(false);
  const { sort, handleSort } = useTableSort(
    {
      sortBy: "firstName",
      sortDirection: "ascending",
    },
    "service-provider-list",
  );

  const sortOptions = [{ sortBy: "firstName", label: "Name" }];

  const {
    data: facilityObjectOwners,
    isFetching,
    isSuccess,
  } = useGetFacilityObjectOwnersQuery({
    customerToken,
    facilityObjectId,
    withActiveAccountsCount: true,
  });

  const isActiveStakeholderInitialState: StakeholdersInfo = {
    addressId: 0,
    firstName: "",
    lastName: "",
    activeAccountsCount: 0,
  };

  const sortedFacilityObjectOwners = useSortStakeholders(
    facilityObjectOwners ? facilityObjectOwners : [],
    sort,
  );
  const [isActiveStakeholder, setActiveStakeholder] =
    useState<StakeholdersInfo>(isActiveStakeholderInitialState);

  const {
    selectedStakeholders,
    setSelectedStakeholders,
    handleGetInfoUsersAndCode,
    handleOnChange,
    exportToCSV,
    isLoading,
    registrationData,
    isError,
  } = useStakeholdersRegistration(sortedFacilityObjectOwners);

  const {
    handleCreateNewCode,
    error,
    isSuccess: isSuccessCreateNewCode,
    isLoadingOnlineIdentityForTenant,
    isLoadingNewRegistrationCodeForOnlineIdentity,
  } = useCreateNewCode(selectedStakeholders);

  useEffect(() => {
    if (error || isError) {
      setErrorDialogOpen(true);
    }
  }, [error, isError]);

  useEffect(() => {
    if (isSuccessCreateNewCode) {
      setSuccessDialogOpen(true);
    }
  }, [isSuccessCreateNewCode]);

  useEffect(() => {
    if (!isLoading && registrationData) {
      exportToCSV();
    }
  }, [registrationData, isLoading]);

  const handleExportToCSV = useCallback(() => {
    handleGetInfoUsersAndCode();
  }, []);

  return (
    <section className="object-owner-wrapper">
      {isActiveStakeholder.addressId !== 0 && (
        <span
          onClick={() => setActiveStakeholder(isActiveStakeholderInitialState)}
          role="button"
          className="object-owner-back"
        >
          <CaretDown width={16} height={16} /> Alle Hauseigentümer
        </span>
      )}
      {isActiveStakeholder.addressId === 0 && (
        <div className="object-owner-container">
          <div className="object-owner-info">
            <h3>Hauseigentümer</h3>
            {isPermissionCanManageTenant && (
              <div className={"object-owner-actions"}>
                {selectedStakeholders.length > 0 ? (
                  <span>
                    {selectedStakeholders.length} Hauseigentümer ausgewählt
                  </span>
                ) : (
                  <div> </div>
                )}
                <div className={"object-owner-actions-buttons"}>
                  <Button
                    leadingIcon={CrossIcon}
                    label={"Registrierungscodes ausstellen"}
                    className={"object-tenant-create-code"}
                    disabled={selectedStakeholders.length === 0}
                    onClick={handleCreateNewCode}
                  />
                  <Button
                    leadingIcon={DownloadIcon}
                    label={"Registrierungsdaten exportieren"}
                    className={"object-tenant-create-code"}
                    disabled={selectedStakeholders.length === 0 || isLoading}
                    onClick={handleExportToCSV}
                  />
                </div>
              </div>
            )}
            {facilityObjectOwners && facilityObjectOwners.length === 0 && (
              <InfoText
                isInfo={true}
                textInfo="Diesem Objekt sind zurzeit keine Hauseigentümer zugeordnet."
              />
            )}
            {facilityObjectOwners && facilityObjectOwners.length > 0 && (
              <div className="sort-header">
                {isPermissionCanManageTenant && (
                  <Checkbox
                    label={""}
                    onChange={handleOnChange}
                    checked={
                      selectedStakeholders.length ===
                      sortedFacilityObjectOwners.length
                    }
                  />
                )}
                <TableSortHeader
                  handleSort={handleSort}
                  sortOptions={sortOptions}
                  sort={sort}
                />
              </div>
            )}

            <div className="object-owner-info-list-body" role="row">
              {sortedFacilityObjectOwners?.map((facilityObjectOwner, index) => (
                <StakeholdersInfoListItem
                  key={index}
                  stakeholdersInfo={facilityObjectOwner}
                  isActive={
                    facilityObjectOwner.addressId ===
                    isActiveStakeholder.addressId
                  }
                  setActiveStakeholder={setActiveStakeholder}
                  setSelectedTenants={setSelectedStakeholders}
                  selectedTenants={selectedStakeholders}
                  isPermissionCanManageTenant={isPermissionCanManageTenant}
                />
              ))}

              {!isSuccess && isFetching && (
                <Fragment>
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                  <StakeholdersInfoListItemSkeleton />
                </Fragment>
              )}
            </div>
          </div>

          {(error || isError) && isErrorDialogOpen && (
            <AlertDialog
              title="Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut."
              onConfirm={() => setErrorDialogOpen(false)}
              description=""
            />
          )}

          {isSuccessCreateNewCode && isSuccessDialogOpen && (
            <AlertDialog
              title="Die Registrierungscodes wurden erfolgreich ausgestellt."
              onConfirm={() => setSuccessDialogOpen(false)}
              description=""
            />
          )}
        </div>
      )}

      {isActiveStakeholder.addressId !== 0 && (
        <StakeholdersInfoListItemUsersAndCode
          isActiveStakeholder={isActiveStakeholder}
        />
      )}

      {(isLoadingNewRegistrationCodeForOnlineIdentity ||
        isLoadingOnlineIdentityForTenant) && (
        <UpdateDialog
          description=""
          title="Registrierungs codes werden ausgestellt"
        />
      )}
      {isLoading && (
        <UpdateDialog description="" title="Export wird vorbereitet.." />
      )}
    </section>
  );
}
