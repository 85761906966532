import {
  FacilityDocumentRolePermissionsGrantedUserEventModel,
  NotificationProps,
} from "../../types";
import { getShortenText } from "../../lib/notifications";
import NotificationItemDate from "./NotificationItemDate";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export default function FacilityDocumentRolePermissionsGrantedUserEventNotification({
  userEventData,
  profilePicture,
  markUserEventsAsRead,
  className,
  createdByUserName,
}: NotificationProps): ReactElement {
  const { facilityObjectId, customerToken, raisedAtUtc, text } =
    userEventData as FacilityDocumentRolePermissionsGrantedUserEventModel;

  return (
    <Link
      to={`/${customerToken}/objekte/${facilityObjectId}/dokumente/objekt`}
      onClick={markUserEventsAsRead}
      className={className}
      data-testid="notification-item"
    >
      <span className="notification-item-profile-picture">
        {profilePicture}
      </span>
      <div className="notification-item-content">
        <strong>{createdByUserName}</strong> <span>{getShortenText(text)}</span>
        <NotificationItemDate date={raisedAtUtc} />
      </div>
    </Link>
  );
}
