import React, { Fragment } from "react";
import { View, Text } from "@react-pdf/renderer";
import PdfDocumentVotingResult from "./PdfDocumentVotingResult";
import {
  PropositionAcceptanceStatus,
  PropositionVotingType,
} from "../../../enums";
import PdfDocumentResolutionInfo from "./PdfDocumentResolutionInfo";
import { PropositionsResultForPDF } from "../../../types";
import PdfStyles from "./PdfStyles";

export default function PdfDocumentMeetingResultsListItem({ item }: Props) {
  const isTotalValuesNull =
    item.votingResult &&
    item.votingResult.qualificationValues.totalYesValues +
      item.votingResult.qualificationValues.totalNoValues +
      item.votingResult.qualificationValues.totalAbstentionsValues ===
      0;

  return (
    <View style={PdfStyles.section}>
      <View style={PdfStyles.topicHeader}>
        <Text style={PdfStyles.topicHeaderNumber}>
          TOP {item.agendaItemNumber}:{" "}
        </Text>
        <Text>{item.agendaItemTitle}</Text>
      </View>
      {item.description && (
        <View style={PdfStyles.description}>
          <View>
            <Text style={PdfStyles.title}>Beschreibung</Text>
          </View>
          <View>
            <Text style={PdfStyles.text}>{item.description}</Text>
          </View>
        </View>
      )}
      {item.propositionTitle ? (
        <View style={PdfStyles.description}>
          <View>
            <Text style={PdfStyles.title}>Beschlussantrag</Text>
          </View>
          <View>
            <Text style={PdfStyles.text}>{item.propositionTitle}</Text>
          </View>
        </View>
      ) : (
        <div> </div>
      )}

      {item.votingResult && item.votingType !== null && (
        <View style={PdfStyles.numberOfParticipants}>
          <Text style={PdfStyles.count}>
            {item.votingType === PropositionVotingType.Simple
              ? "Stimmberechtigte Teilnehmer: "
              : "Stimmberechtigte Anteile: "}
          </Text>
          <View>
            <Text>
              {item.votingResult && !isTotalValuesNull
                ? item.votingResult.qualificationValues.totalAttendeesValues
                : 0}
            </Text>
          </View>
        </View>
      )}
      {item.votingResult && (
        <Fragment>
          <Text style={PdfStyles.title}>Abstimmungsergebnisse</Text>
          <PdfDocumentVotingResult item={item} />
        </Fragment>
      )}
      {item.votingResult?.acceptanceStatus ===
        PropositionAcceptanceStatus.Accepted && (
        <PdfDocumentResolutionInfo item={item} />
      )}
    </View>
  );
}

type Props = {
  item: PropositionsResultForPDF;
};
