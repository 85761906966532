import React, { ReactElement, SyntheticEvent } from "react";

import "../../../styles/components/common/Checkbox.scss";

export default function Checkbox({
  label,
  disabled,
  defaultChecked,
  onChange,
  value,
  checked,
}: Props): ReactElement {
  return (
    <section className="checkbox-wrapper">
      <label className="checkbox-field-label">
        <input
          className="checkbox-input"
          data-testid="checkbox"
          type="checkbox"
          disabled={disabled}
          defaultChecked={defaultChecked}
          onChange={onChange}
          defaultValue={value}
          checked={checked}
        />
        <span className="checkbox-field-label">{label}</span>
      </label>
    </section>
  );
}

type Props = {
  label: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  checked?: boolean;
} & Partial<HTMLInputElement>;
