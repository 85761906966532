import {
  MyInvitation,
  Participant,
  ParticipantVoteDirective,
} from "../../../types";
import { Fragment } from "react";
import moment from "moment";
import { ParticipantDelegationType } from "../../../enums";

import "../../../styles/components/meetings/inivitation/InvitationState.scss";
import Checkmark from "../../icons/Checkmark";
import CloseIcon from "../../icons/CloseIcon";

export default function InvitationState({
  myInvitation,
  myRelatedParticipant,
  instructions,
}: Props) {
  const formatDate = (date: string | undefined) =>
    date ? moment.utc(date).local().format("L") : null;

  const invitationRespondedAt = formatDate(
    myInvitation?.invitationRespondedAtUtc,
  );
  const instructionsCreatedAt = formatDate(instructions?.createdAtUtc);
  const delegateConfirmedAt = formatDate(
    myRelatedParticipant?.delegate?.confirmedAtUtc,
  );

  const isDelegated =
    myRelatedParticipant?.delegationType ===
      ParticipantDelegationType.VotingEligibilityDelegatedToParticipant ||
    myRelatedParticipant?.delegationType ===
      ParticipantDelegationType.VotingEligibilityDelegatedToEmployee;

  const delegation = myRelatedParticipant && isDelegated;

  return (
    <Fragment>
      {myInvitation && (
        <div className="meeting-invitation-state">
          {myInvitation.invitationAccepted && (
            <div className="accepted">
              <Checkmark width={32} height={32} className="icon-accepted" />
              {`Sie haben die Einladung am ${invitationRespondedAt} zugesagt.`}
            </div>
          )}
          {!myInvitation.invitationAccepted && (
            <div className="declined">
              <CloseIcon height={32} width={32} className="icon-declined" />
              <span className="text-declined">
                {`Sie haben die Einladung zur Eigentümerversammlung am ${invitationRespondedAt} abgesagt${
                  delegation && instructions?.instructions[0]
                    ? ` und am ${myRelatedParticipant?.delegationType === ParticipantDelegationType.VotingEligibilityDelegatedToParticipant ? delegateConfirmedAt : invitationRespondedAt} eine Vollmacht erteilt und am ${instructionsCreatedAt} Weisungen abgegeben.`
                    : delegation
                      ? ` und am ${myRelatedParticipant?.delegationType === ParticipantDelegationType.VotingEligibilityDelegatedToParticipant ? delegateConfirmedAt : invitationRespondedAt} eine Vollmacht erteilt.`
                      : instructions?.instructions[0]
                        ? ` und am ${instructionsCreatedAt} Weisungen abgegeben.`
                        : ". Es wurde weder eine Vollmacht erteilt noch Weisungen abgegeben."
                }`}
              </span>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}

type Props = {
  myRelatedParticipant?: Participant;
  myInvitation?: MyInvitation;
  instructions?: ParticipantVoteDirective | undefined;
};
