import { Field, Form } from "react-final-form";
import { useParams } from "react-router-dom";

import React, { ReactNode, useCallback, useMemo, useState } from "react";

import { FORM_ERROR } from "final-form";

import { useSynchronisation } from "../../../hooks/useSynchronisation";
import Button from "../../common/Button";
import CheckboxField from "../../common/form/CheckboxField";
import RadioButton from "../../common/form/RadioButton";

import {
  useGetMeetingQualificationDistributionKeysQuery,
  useGetMeetingVotingQualificationRulesQuery,
} from "../../../api/meetingApi";
import { useUpdatePropositionVotingQualificationRulesMutation } from "../../../api/propositionApi";

import {
  PropositionVotingPrincipleType,
  PropositionVotingState,
  PropositionVotingType,
  SynchronisationMethod,
} from "../../../enums";

import Checkmark from "../../icons/Checkmark";

import useUpdateProposition from "../../../hooks/meetings/useUpdateProposition";
import LoadingSpinner from "../../icons/LoadingSpinner";
import ConfirmDialog from "../../common/dialog/ConfirmDialog";

import Select from "../../common/form/Select";
import { ForeignPropositionVotingRule, Proposition } from "../../../types";

import "../../../styles/components/meetings/proposition/PropositionVotingQualificationRulesUpdateForm.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseValueToInt = (value: any) =>
  value !== undefined ? parseInt(value, 10) : value;

const isHeadPrinciple = (
  qualificationRules: ForeignPropositionVotingRule[] = [],
  ruleId?: number,
) =>
  qualificationRules.find((rule) => rule.id === ruleId)?.votingPrinciple ===
  PropositionVotingPrincipleType.HeadPrinciple;

const validateOptions = (value: string) =>
  parseInt(value, 10) === -1 ? "Wird benötigt" : undefined;

export default function PropositionVotingQualificationRulesUpdateForm({
  proposition,
  onCancel,
}: Props): ReactNode {
  const {
    customerToken = "",
    meetingId = "",
    facilityObjectId = "",
    agendaItemId = "",
  } = useParams();

  const { id: propositionId } = proposition;
  const { isLoading } = useUpdateProposition(propositionId);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const { sync } = useSynchronisation();

  const { data: allMeetingVotingQualificationRules = [] } =
    useGetMeetingVotingQualificationRulesQuery({
      customerToken,
      facilityObjectId,
      meetingId,
    });

  const { data: allMeetingQualificationDistributionKeys = [] } =
    useGetMeetingQualificationDistributionKeysQuery({
      customerToken,
      facilityObjectId,
      meetingId,
    });

  const [updatePropositionVotingQualificationRules] =
    useUpdatePropositionVotingQualificationRulesMutation();

  const onSubmit = useCallback(
    async (values: FormData) => {
      if (
        proposition.votingState === PropositionVotingState.Closed &&
        !confirmUpdate
      ) {
        setConfirmUpdate(true);
        return;
      }
      const {
        doubleQualificationVotingRuleId,
        propositionWithDoubleQualificationRule,
        doubleQualificationDistributionKeyId,
        qualificationVotingRuleId,
        qualificationDistributionKeyId,
        propositionVotingType,
      } = values;

      const data = {
        customerToken,
        meetingId,
        facilityObjectId,
        agendaItemId,
        propositionId,
        qualificationVotingRuleId,
        votingType: propositionVotingType,
        qualificationDistributionKeyId: !isHeadPrinciple(
          allMeetingVotingQualificationRules,
          qualificationVotingRuleId,
        )
          ? qualificationDistributionKeyId
          : undefined,
        doubleQualificationVotingRuleId: propositionWithDoubleQualificationRule
          ? doubleQualificationVotingRuleId
          : undefined,
        doubleQualificationDistributionKeyId:
          propositionWithDoubleQualificationRule &&
          !isHeadPrinciple(
            allMeetingVotingQualificationRules,
            doubleQualificationVotingRuleId,
          )
            ? doubleQualificationDistributionKeyId
            : undefined,
      };

      setConfirmUpdate(false);
      const result = await updatePropositionVotingQualificationRules(data);

      if ("error" in result) {
        return {
          [FORM_ERROR]:
            "Abstimmungsparameter konnten nicht gespeichert werden!",
        };
      }
      onCancel();
      sync(SynchronisationMethod.PropositionChanged);
    },
    [allMeetingVotingQualificationRules, proposition, confirmUpdate],
  );

  const qualificationDistributionKeyOptions = useMemo(
    () => [
      { label: "Bitte Wählen", value: -1 },
      ...allMeetingQualificationDistributionKeys.map(
        ({ distributionKeyId, name }) => ({
          label: name,
          value: distributionKeyId,
        }),
      ),
    ],
    [allMeetingQualificationDistributionKeys],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        propositionVotingType: proposition.votingType,
        qualificationVotingRuleId:
          proposition.qualificationVotingRule?.foreignVotingRuleId,
        qualificationDistributionKeyId:
          proposition.qualificationVotingRule?.foreignDistributionKeyId ?? -1,
        propositionWithDoubleQualificationRule:
          proposition.doubleQualificationVotingRule !== null,
        doubleQualificationVotingRuleId:
          proposition.doubleQualificationVotingRule?.foreignVotingRuleId ?? -1,
        doubleQualificationDistributionKeyId:
          proposition.doubleQualificationVotingRule?.foreignDistributionKeyId ??
          -1,
      }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        error,
        values,
        form,
        valid,
      }) => {
        const qualificationVotingRuleOptions = useMemo(() => {
          const options = (
            values.propositionVotingType === PropositionVotingType.Simple
              ? allMeetingVotingQualificationRules.filter(
                  (r) =>
                    r.votingPrinciple ===
                    PropositionVotingPrincipleType.HeadPrinciple,
                )
              : allMeetingVotingQualificationRules
          )
            .map(({ id, name }) => ({ label: name, value: id }))
            .sort((a, b) => {
              return a.label.localeCompare(b.label);
            });

          return [{ label: "Bitte Wählen", value: -1 }, ...options];
        }, [values.propositionVotingType, allMeetingVotingQualificationRules]);

        const resetQualification = useCallback(() => {
          form.reset({
            ...values,
            doubleQualificationVotingRuleId: -1,
            doubleQualificationDistributionKeyId: -1,
            qualificationVotingRuleId: -1,
            qualificationDistributionKeyId: -1,
          });
        }, [form, values]);
        return (
          <div className="agenda-item-proposition-voting-qualification-rules-update-form">
            <form
              onSubmit={handleSubmit}
              data-testid="agenda-item-proposition-voting-qualification-rules-update-form"
            >
              <div className="agenda-item-proposition-voting-qualification-rules-update-form-row small-text">
                Abstimmungsart
              </div>
              <div className="agenda-item-proposition-voting-qualification-rules-update-form-row">
                <Field
                  name="propositionVotingType"
                  component={RadioButton}
                  type="radio"
                  label="Detalliert"
                  required
                  parse={parseValueToInt}
                  value={String(PropositionVotingType.Detailed)}
                  defaultChecked={
                    proposition.votingType === PropositionVotingType.Detailed
                  }
                  onClick={resetQualification}
                />
                <Field
                  name="propositionVotingType"
                  component={RadioButton}
                  type="radio"
                  label="Einfach"
                  required
                  value={String(PropositionVotingType.Simple)}
                  parse={parseValueToInt}
                  defaultChecked={
                    proposition.votingType === PropositionVotingType.Simple
                  }
                  onClick={resetQualification}
                />
                <Field
                  name="propositionWithDoubleQualificationRule"
                  component={CheckboxField}
                  type="checkbox"
                  label="Doppelt qualifizierter Beschluss"
                />
              </div>
              <div className="agenda-item-proposition-voting-qualification-rules-update-form-row">
                <Field
                  name="qualificationVotingRuleId"
                  component={Select}
                  type="select"
                  label="Stimmrecht"
                  options={qualificationVotingRuleOptions}
                  validate={validateOptions}
                  parse={parseValueToInt}
                  required
                />
                {!isHeadPrinciple(
                  allMeetingVotingQualificationRules,
                  values.qualificationVotingRuleId,
                ) &&
                  values.propositionVotingType !==
                    PropositionVotingType.Simple && (
                    <Field
                      name="qualificationDistributionKeyId"
                      component={Select}
                      type="select"
                      label="Schlüssel"
                      options={qualificationDistributionKeyOptions}
                      parse={parseValueToInt}
                      validate={validateOptions}
                      required
                    />
                  )}
              </div>
              {values?.propositionWithDoubleQualificationRule && (
                <div className="agenda-item-proposition-voting-qualification-rules-update-form-row">
                  <Field
                    name="doubleQualificationVotingRuleId"
                    component={Select}
                    type="select"
                    label="Stimmrecht 2"
                    parse={parseValueToInt}
                    validate={validateOptions}
                    options={qualificationVotingRuleOptions}
                    required
                  />
                  {!isHeadPrinciple(
                    allMeetingVotingQualificationRules,
                    values.doubleQualificationVotingRuleId,
                  ) &&
                    values.propositionVotingType !==
                      PropositionVotingType.Simple && (
                      <Field
                        name="doubleQualificationDistributionKeyId"
                        component={Select}
                        type="select"
                        label="Schlüssel 2"
                        parse={parseValueToInt}
                        validate={validateOptions}
                        options={qualificationDistributionKeyOptions}
                        required
                      />
                    )}
                </div>
              )}
              <hr />
              {error && (
                <div className="agenda-item-proposition-voting-qualification-rules-update-form-error">
                  error
                </div>
              )}
              <div
                className="agenda-item-proposition-voting-qualification-rules-update-form-actions"
                data-testid="agenda-item-proposition-voting-qualification-rules-update-form-actions"
              >
                <Button
                  leadingIcon={isLoading ? LoadingSpinner : Checkmark}
                  label="Parameter übernehmen"
                  lightblue
                  type="submit"
                  disabled={!valid || pristine || submitting}
                />
                <Button label="Abbrechen" onClick={onCancel} />
              </div>
              {confirmUpdate && (
                <ConfirmDialog
                  description="Durch das ändern der Abstimmungsparameter kann das aktuelle Ergebnis der Abstimmung nicht beibehalten werden. Möchten Sie die Parameter übernehmen und die Abstimmung zurücksetzen?"
                  title="Abstimmung zurücksetzen"
                  onConfirm={handleSubmit}
                  confirmLabel="Ja, zurücksetzen"
                  onCancel={() => setConfirmUpdate(false)}
                  cancelLabel="Abbrechen"
                />
              )}
            </form>
          </div>
        );
      }}
    />
  );
}

type FormData = {
  propositionVotingType: PropositionVotingType;
  qualificationVotingRuleId: number;
  qualificationDistributionKeyId?: number;
  propositionWithDoubleQualificationRule: boolean;
  doubleQualificationVotingRuleId?: number;
  doubleQualificationDistributionKeyId?: number;
};

type Props = {
  proposition: Proposition;
  onCancel: () => void;
};
