import React from "react";
import {
  QualificationVotingRule,
  QualificationVotingValues,
} from "../../../types";

export default function PropositionVotingResultDetails({
  qualificationVotingRule,
  qualificationValues,
  totalAttendedVotes,
  totalYesVotes,
  totalNoVotes,
  totalAbstentionsVotes,
}: Props): JSX.Element {
  const totalVotes = totalYesVotes + totalNoVotes + totalAbstentionsVotes;
  const qualificationValuesUsed =
    qualificationValues.totalYesValues +
    qualificationValues.totalNoValues +
    qualificationValues.totalAbstentionsValues;

  const isHeadPrinciple = qualificationVotingRule.name.includes("Kopfprinzip");
  return (
    <table className="agenda-item-proposition-voting-result-details">
      <tbody>
        <tr>
          <td className="agenda-item-proposition-voting-result-details-title">
            Stimmrecht:
          </td>
          <td>{qualificationVotingRule.name}</td>
        </tr>
        <tr>
          <td className="agenda-item-proposition-voting-result-details-title">
            Abgestimmt:
          </td>
          <td>
            {`${totalVotes} / ${totalAttendedVotes} `}
            Eigentümer
            {!isHeadPrinciple &&
              ` (${qualificationValuesUsed} / ${qualificationValues.totalAttendeesValues})`}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

type Props = {
  qualificationVotingRule: QualificationVotingRule;
  qualificationValues: QualificationVotingValues;
  totalAttendedVotes: number;
  totalYesVotes: number;
  totalNoVotes: number;
  totalAbstentionsVotes: number;
};
