import React, { ReactNode } from "react";

import "../../styles/components/common/TabBar.scss";
import { useOutletContext } from "react-router-dom";
import classNames from "classnames";

export default function TabBar({ children }: Props): JSX.Element {
  type OutletContext = [boolean];
  const [mobile] = useOutletContext<OutletContext>();
  const className = classNames("tab-bar", {
    mobile: mobile,
  });
  return (
    <div className={className} role="tablist">
      <div className="tab-bar-scroller">
        <div className="tab-bar-scroller-area">
          <div className="tab-bar-scroller-content">{children}</div>
        </div>
      </div>
    </div>
  );
}

type Props = {
  children: ReactNode | ReactNode[];
};
