import React, { Fragment } from "react";
import {
  AgendaItemType,
  MeetingState,
  PropositionVotingState,
  UserRoles,
} from "../../../enums";
import PropositionUpdateForm from "./PropositionUpdateForm";

export default function PropositionSummary({
  addition,
  additionNumber,
  title,
  roles,
  meetingState,
  votingState,
  id,
  agendaItemType,
}: Props): JSX.Element {
  const valueAdditionNumber = additionNumber ? `(${additionNumber})` : "";

  const propositionSummaryPrefixTitle =
    votingState === PropositionVotingState.Running ||
    votingState === PropositionVotingState.Closed
      ? "Beschlussantrag"
      : "Beschlussentwurf";

  return (
    <Fragment>
      {agendaItemType === AgendaItemType.ParticipantsRegistration &&
      roles.includes(UserRoles.Employee) ? (
        "Erfassung der Anwesenheit"
      ) : (
        <Fragment>
          <span className="proposition-summary-prefix">{`${propositionSummaryPrefixTitle} ${
            addition ? `(${addition})` : valueAdditionNumber
          }`}</span>
          <PropositionUpdateForm
            initialValues={{ title }}
            roles={roles}
            meetingState={meetingState}
            votingState={votingState}
            id={id}
            agendaItemType={agendaItemType}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

type Props = {
  addition?: string;
  additionNumber: number;
  title: string;
  roles: UserRoles[];
  meetingState: MeetingState;
  votingState: PropositionVotingState;
  id: string;
  agendaItemType: AgendaItemType;
};
