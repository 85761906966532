import { ReactElement, useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "react-intersection-observer-hook";

import { Link, useParams } from "react-router-dom";
import { FacilityObject } from "../../types";
import SwitchInput from "../common/SwitchInput";
import classNames from "classnames";
import { useGetBulletinBoardQuery } from "../../api/bulletinBoardApi";
import useToggleTenantCanPost from "../../hooks/bulletinBoard/useToggleTenantCanPost";
import useToggleOnline from "../../hooks/facilityObject/useToggleOnline";
import useToggleTenantCanComment from "../../hooks/useToggleTenantCanComment";
export default function FacilityObjectsTableItem({
  facilityObject,
}: Props): ReactElement {
  const { customerToken = "" } = useParams();
  const [skip, setSkip] = useState(true);
  const [tenantsCanPost, setTenantsCanPost] = useState(false);
  const [tenantsCanComment, setTenantsCanComment] = useState(false);
  const [intersectionCallback, { entry }] = useIntersectionObserver();
  const ref = useRef<HTMLDivElement | null>(null);
  const { data } = useGetBulletinBoardQuery(
    {
      customerToken,
      facilityObjectId: String(facilityObject.id),
    },
    { skip },
  );
  const { toggleTenantsCanPost, isLoading: toggleTenantCanPostLoading } =
    useToggleTenantCanPost(String(facilityObject.id), data);

  const { toggleTenantsCanComment, isLoading: toggleTenantCanCommentLoading } =
    useToggleTenantCanComment(facilityObject, data);
  const {
    isLoading: isFacilityObjectLoading,
    toggleOnline: facilityObjectToggleOnline,
  } = useToggleOnline(facilityObject);

  useEffect(() => {
    if (ref) {
      intersectionCallback(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      setSkip(false);
    }
  }, [entry, setSkip, facilityObject]);

  useEffect(() => {
    if (data) {
      setTenantsCanPost(data.tenantsCanPost);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setTenantsCanComment(data.tenantsCanComment);
    }
  }, [data]);

  return (
    <div className="management-facility-objects-table-item" role="row">
      <Link
        className={"management-facility-objects-table-item-text-wrapper"}
        to={`./${facilityObject.id}/objekt`}
        reloadDocument={false}
      >
        <div className="management-facility-objects-table-item-id" role="cell">
          {facilityObject.number}
        </div>
        <div
          className="management-facility-objects-table-item-description"
          role="cell"
          data-testid="item-description"
        >
          {facilityObject.description}
        </div>
      </Link>

      <div
        className={classNames("management-facility-objects-table-item-state", {
          online: facilityObject.isOnline,
        })}
        role="cell"
      >
        <SwitchInput
          disabled={isFacilityObjectLoading}
          onChange={facilityObjectToggleOnline}
          defaultChecked={facilityObject.isOnline || false}
          label={facilityObject.isOnline ? "Online" : "Offline"}
        />
      </div>
      <div
        className={classNames(
          "management-facility-objects-table-item-user-entries",
          {
            online: data && data.tenantsCanPost,
          },
        )}
        role="cell"
        ref={ref}
      >
        <SwitchInput
          onChange={toggleTenantsCanPost}
          checked={tenantsCanPost}
          disabled={
            toggleTenantCanPostLoading || !facilityObject.isOnline || !data
          }
          label={data && data.tenantsCanPost ? "An" : "Aus"}
        />
      </div>
      <div
        className={classNames(
          "management-facility-objects-table-item-user-entries",
          {
            online: data && data.tenantsCanComment,
          },
        )}
        role="cell"
        ref={ref}
      >
        <SwitchInput
          onChange={toggleTenantsCanComment}
          checked={tenantsCanComment}
          disabled={
            toggleTenantCanCommentLoading || !facilityObject.isOnline || !data
          }
          label={data && data.tenantsCanComment ? "An" : "Aus"}
        />
      </div>
    </div>
  );
}

type Props = {
  facilityObject: FacilityObject;
};
