import React, { ReactElement, useCallback, useEffect, useState } from "react";

import PropositionVotingControlForm from "./PropositionVotingControlForm";
import {
  MeetingState,
  PropositionVotingState,
  SynchronisationMethod,
  UserRoles,
} from "../../../enums";
import usePropositionVotingControl from "../../../hooks/meetings/usePropositionVotingControl";
import UpdateDialog from "../../common/dialog/UpdateDialog";
import { useSynchronisation } from "../../../hooks/useSynchronisation";

import "../../../styles/components/meetings/proposition/PropositionVotingControl.scss";
import PropositionVotingQualificationRulesUpdateForm from "./PropositionVotingQualificationRulesUpdateForm";
import Button from "../../common/Button";
import SettingsIcon from "../../icons/SettingsIcon";
import useCustomerRoles from "../../../hooks/useCustomerRoles";
import { Proposition } from "../../../types";
import { shouldRenderVotingControl } from "../../../lib/meeting";

export default function PropositionVotingControl({
  meetingState,
  proposition,
  agendaItemId,
}: Props): ReactElement {
  const { id, votingState, votingType } = proposition;
  const {
    handleVotingControl,
    isLoading,
    isVotingRunning,
    startVotingSuccess,
    stopVotingSuccess,
    stopSimpleVotingSuccess,
    isError,
  } = usePropositionVotingControl(id, votingState, votingType);

  const roles = useCustomerRoles();

  const [
    showPropositionVotingQualificationRulesUpdateForm,
    setShowPropositionVotingQualificationRulesUpdateForm,
  ] = useState(false);

  const { sync } = useSynchronisation();

  const isDisabled =
    meetingState !== MeetingState.Running ||
    isLoading ||
    (isVotingRunning && votingState !== PropositionVotingState.Running);

  const updateDialogTitle =
    votingState === PropositionVotingState.Running
      ? "Abstimmung wird beendet"
      : "Abstimmung wird gestartet";

  const updateDialogDescription =
    votingState === PropositionVotingState.Running
      ? "Die aktuelle Abstimmung wird beendet"
      : "Die aktuelle Abstimmung wird gestartet";

  const shouldRenderPropositionVotingQualificationRulesEditButton =
    meetingState !== MeetingState.Closed &&
    roles.includes(UserRoles.Employee) &&
    !showPropositionVotingQualificationRulesUpdateForm;

  const handleActivatePropositionVotingQualificationRulesFormButton =
    useCallback(() => {
      setShowPropositionVotingQualificationRulesUpdateForm(
        !showPropositionVotingQualificationRulesUpdateForm,
      );
    }, [showPropositionVotingQualificationRulesUpdateForm]);

  useEffect(() => {
    if (
      !isError &&
      !isLoading &&
      (startVotingSuccess || stopVotingSuccess || stopSimpleVotingSuccess)
    ) {
      sync(
        SynchronisationMethod.PropositionVotingStateChanged,
        agendaItemId,
        id,
      );
    }
  }, [
    startVotingSuccess,
    stopVotingSuccess,
    id,
    isLoading,
    isError,
    stopSimpleVotingSuccess,
  ]);

  return (
    <div
      className="agenda-item-proposition-voting-control"
      data-testid="proposition-voting-control"
    >
      {showPropositionVotingQualificationRulesUpdateForm && (
        <PropositionVotingQualificationRulesUpdateForm
          proposition={proposition}
          onCancel={handleActivatePropositionVotingQualificationRulesFormButton}
        />
      )}
      {votingState === PropositionVotingState.Running && (
        <div className="agenda-item-proposition-voting-control-form-text">
          Abstimmung läuft...
        </div>
      )}
      <div className="agenda-item-proposition-voting-control-actions">
        {shouldRenderPropositionVotingQualificationRulesEditButton && (
          <Button
            label="Abstimmungsparameter"
            className="agenda-item-proposition-voting-qualification-rules-update-form-button"
            leadingIcon={SettingsIcon}
            disabled={isVotingRunning}
            title={
              isVotingRunning
                ? "Ändern der Abstimmungsparameter während laufender Abstimmung nicht möglich"
                : ""
            }
            onClick={
              handleActivatePropositionVotingQualificationRulesFormButton
            }
          />
        )}
        {shouldRenderVotingControl(roles, proposition, meetingState) && (
          <PropositionVotingControlForm
            onSubmit={handleVotingControl}
            initialValues={{
              time: 2,
              totalYesVotes: 0,
              totalNoVotes: 0,
              totalAbstentionVotes: 0,
              totalAttendedParticipants: 0,
            }}
            disabled={isDisabled}
            votingState={votingState}
            votingType={votingType}
            propositionId={id}
          />
        )}
      </div>
      {isLoading && (
        <UpdateDialog
          description={updateDialogDescription}
          title={updateDialogTitle}
        />
      )}
    </div>
  );
}

type Props = {
  meetingState: MeetingState;
  proposition: Proposition;
  agendaItemId: string;
};
