import { BulletinBoardPostComment } from "../../types";
import PostByUser from "./PostByUser";
import PostCommentMenu from "./PostCommentMenu";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import PostEditComment from "./PostEditComment";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import ConfirmDialog from "../common/dialog/ConfirmDialog";
import {
  useDeleteBulletinBoardPostCommentMutation,
  usePinBulletinBoardPostCommentMutation,
  useUnpinBulletinBoardPostCommentMutation,
} from "../../api/bulletinBoardApi";
import Pin from "../icons/Pin";
import LinkifyContent from "../common/LinkifyContent";
export default function PostCommentsItem({
  comment,
  facilityObjectId,
  customerToken,
}: Props): ReactElement {
  const [edit, setEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteComment] = useDeleteBulletinBoardPostCommentMutation();
  const [pinComment] = usePinBulletinBoardPostCommentMutation();
  const [unpinComment] = useUnpinBulletinBoardPostCommentMutation();
  const ref = useRef(null);
  const {
    createdByUser,
    id,
    text,
    createDateUTC,
    canEdit,
    canDelete,
    bulletinBoardPostId,
  } = comment;

  const linkifyContent = createdByUser?.isEmployee ?? false;

  useOnClickOutside(ref, () => {
    setEdit(false);
  });

  const handlePin = useCallback(() => {
    comment.pinned
      ? unpinComment({
          commentId: comment.id,
          customerToken,
          facilityObjectId,
          bulletinBoardPostId: comment.bulletinBoardPostId,
        })
      : pinComment({
          commentId: comment.id,
          customerToken,
          facilityObjectId,
          bulletinBoardPostId: comment.bulletinBoardPostId,
        });
  }, [comment]);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleDelete = useCallback(() => {
    setShowDelete(true);
  }, []);

  const handleCancelDelete = useCallback(() => {
    setShowDelete(false);
  }, []);

  const handeDeleteComment = useCallback(() => {
    deleteComment({
      commentId: id,
      customerToken,
      facilityObjectId,
      bulletinBoardPostId,
    });
    setShowDelete(false);
  }, [deleteComment]);

  useEffect(() => {
    setEdit(false);
  }, [text]);

  return (
    <div
      className="bulletin-board-post-list-item-comments-list-item"
      key={id}
      role="listitem"
      ref={ref}
    >
      <div className="bulletin-board-post-list-item-comments-list-item-user">
        <PostByUser
          createdByUser={createdByUser}
          createDateUTC={createDateUTC}
        />
        {comment.pinned && (
          <Pin className="bulletin-board-post-list-item-comments-list-item-user-pinned" />
        )}
      </div>
      {!edit && (
        <LinkifyContent linkify={linkifyContent}>
          <p
            className="bulletin-board-post-list-item-comments-list-item-text"
            data-testid="comment-text"
          >
            {text}
          </p>
        </LinkifyContent>
      )}
      {edit && (
        <PostEditComment
          postId={comment.bulletinBoardPostId}
          commentId={comment.id}
          facilityObjectId={facilityObjectId}
          customerToken={customerToken}
          text={text}
        />
      )}
      {showDelete && (
        <ConfirmDialog
          description={`Möchten Sie wirklich den Kommentar "${text}" löschen`}
          title="Kommentar löschen"
          onConfirm={handeDeleteComment}
          onCancel={handleCancelDelete}
          confirmLabel="Löschen"
        />
      )}
      {(canEdit || canDelete) && (
        <PostCommentMenu
          canDelete={canDelete}
          canEdit={canEdit}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          pinned={comment.pinned}
          handlePin={handlePin}
        />
      )}
    </div>
  );
}

type Props = {
  comment: BulletinBoardPostComment;
  facilityObjectId: string;
  customerToken: string;
};
