import { MeetingEventLog } from "../../types";
import { LogEventType, PropositionVotingType } from "../../enums";
import { useMemo } from "react";

export default function useGetEventLogTitle({ item }: Props) {
  const memoizedValues = useMemo(() => {
    const agendaItemAddition = item.eventDetails
      ? item.eventDetails.agendaItemAddition
      : "";
    const agendaItemNumber = item.eventDetails
      ? item.eventDetails.agendaItemNumber
      : "";
    const topNumber = agendaItemAddition
      ? `${agendaItemNumber}.${agendaItemAddition}`
      : agendaItemNumber;
    const createdByEmployeeName = item.createdByEmployeeName;
    const createdByUserName = `${item.createdByUserFirstName} ${item.createdByUserLastName}`;
    const vote =
      item.eventDetails &&
      `${item.eventDetails.vote === 1 ? "Ja" : item.eventDetails.vote === 2 ? "Nein" : item.eventDetails.vote === 3 ? "Enthaltung" : ""}`;
    const personName =
      item.eventDetails &&
      `${item.eventDetails.personFirstName} ${item.eventDetails.personLastName}`;
    const delegatePersonName =
      item.eventDetails &&
      `${item.eventDetails.delegatePersonFirstName} ${item.eventDetails.delegatePersonLastName}`;
    const statusTitle = item.eventDetails ? item.eventDetails.statusTitle : "";
    const propositionTitle = item.eventDetails
      ? item.eventDetails.propositionTitle
      : "";
    const categoryTitle = item.eventDetails
      ? item.eventDetails.categoryTitle
      : "";
    const votingRepeatedVotesPreserved =
      item.eventDetails &&
      item.eventDetails.votingRepeated &&
      item.eventDetails.previousVotesPreserved;

    const propositionVotingStartedTitle = votingRepeatedVotesPreserved
      ? `Die Abstimmung für TOP ${topNumber} wurde wiederholt. Bereits abgegebene Stimmen wurden beibehalten.`
      : `Die Abstimmung für TOP ${topNumber} wurde wiederholt. Bereits abgegebene Stimmen wurden zurückgesetzt.`;

    let logTitle = "";
    switch (item.eventType) {
      case LogEventType.MeetingStarted:
        logTitle = "Versammlung wurde gestartet.";
        break;
      case LogEventType.MeetingEnded:
        logTitle = "Versammlung wurde beendet.";
        break;
      case LogEventType.PropositionVotingStarted:
        logTitle = !item.eventDetails.votingRepeated
          ? `Abstimmung "${propositionTitle}" für TOP ${topNumber} wurde gestartet.`
          : propositionVotingStartedTitle;
        break;
      case LogEventType.PropositionVotingEnded:
        logTitle = `Abstimmung "${propositionTitle}" für TOP ${topNumber} wurde beendet.`;
        break;
      case LogEventType.ParticipantDelegatedVotingEligibility:
        logTitle = `${createdByUserName} hat seine Vollmacht an ${delegatePersonName !== "null null" ? delegatePersonName : "die Hausverwaltung"} erteilt.`;
        break;
      case LogEventType.ParticipantsAttendanceRegisteredByEmployee:
        logTitle = `${createdByEmployeeName} hat ${personName} als Anwesend markiert.`;
        break;
      case LogEventType.ParticipantAttendedMeetingInHomecase:
        logTitle = `${createdByUserName} ist der Versammlung beigetreten.`;
        break;
      case LogEventType.ParticipantLeftMeetingInHomecase:
        logTitle = `${createdByUserName} hat die Versammlung verlassen.`;
        break;
      case LogEventType.VotingEligibilityChangedByEmployee:
        logTitle = `${createdByEmployeeName} hat die Vollmacht von ${personName} an ${delegatePersonName !== "null null" ? delegatePersonName : "die Hausverwaltung"} erteilt.`;
        break;
      case LogEventType.PropositionVotedByParticipant:
        logTitle = `${createdByUserName} hat für ${propositionTitle} mit ${vote} abgestimmt.`;
        break;
      case LogEventType.PropositionVotedByParticipantForAnotherParticipant:
        logTitle = `${createdByUserName} hat für ${personName} in der Abstimmung "${propositionTitle}" für TOP ${topNumber} mit ${vote} abgestimmt - VOLLMACHT.`;
        break;
      case LogEventType.PropositionVotedByEmployeeForParticipant:
        logTitle = item.eventDetails.isVotingEligibilityDelegatedToEmployee
          ? `Der Verwalter ${createdByEmployeeName} hat für ${personName} in der Abstimmung "${propositionTitle}" für TOP ${topNumber} mit ${vote} abgestimmt - VOLLMACHT.`
          : `Der Verwalter ${createdByEmployeeName} hat die Stimme von ${personName} in der Abstimmung "${propositionTitle}" für TOP ${topNumber} mit ${vote} überschrieben.`;
        break;
      case LogEventType.PropositionVotingResultUpdated:
        logTitle = `Für TOP ${topNumber} wurde der Beschluss gefasst.`;
        break;
      case LogEventType.PropositionDecisionHasLegalForceUpdated:
        logTitle = `Für TOP ${topNumber} wurde die Abstimmung als Rechtsfähig anerkannt.`;
        break;
      case LogEventType.PropositionStatusUpdated:
        logTitle = `Für TOP ${topNumber} wurde der Status auf "${statusTitle}" gesetzt.`;
        break;
      case LogEventType.PropositionCategoryUpdated:
        logTitle = `Für TOP ${topNumber} wurde die Kategorie auf "${categoryTitle}" gesetzt.`;
        break;
      case LogEventType.VoteInstructionsForPropositionInvalidated:
        logTitle = `Durch eine Änderung am Beschlussantrag hat die Hausverwaltung die Weisungen für die Abstimmung "${item.eventDetails.propositionTitle}" aufgehoben und damit ungültig gemacht.`;
        break;
      case LogEventType.PropositionTitleUpdated:
        logTitle = `Der Beschlussantrag für die Abstimmung "${item.eventDetails.title}" wurde von der Hausverwaltung überarbeitet. Die erteilten Weisungen bleiben weiterhin gültig.`;
        break;
      case LogEventType.PropositionVotingQualificationRulesUpdated:
        const propositionVotingTypeAsString =
          item.eventDetails.propositionVotingType ===
          (PropositionVotingType.Simple as number)
            ? "Einfache Abstimmung"
            : "Detaillierte Abstimmung";

        const votingRuleName = item.eventDetails.doubleQualificationVotingUsed
          ? `${item.eventDetails.primaryVotingRuleName} (doppelte Qualifizierung: ${item.eventDetails.doubleVotingRuleName})`
          : item.eventDetails.primaryVotingRuleName;
        console.log(item.eventDetails);
        logTitle = `Stimmprinzip für Abstimmung unter TOP ${topNumber} wurde auf "${propositionVotingTypeAsString}; ${votingRuleName}" geändert.`;
        break;
      default:
        logTitle = "";
        break;
    }
    return logTitle;
  }, [item]);

  return memoizedValues;
}

type Props = {
  item: MeetingEventLog;
};
